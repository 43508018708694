import Link from "next/link";
// import { footerMenuList, socialIcons } from "../data/footer_data";
import Image from "next/image";

const footer = () => {
  return (
    <div className="w-[100vw] relative h-[220px]">
      <footer className="w-full h-full page-footer bg-[black] rounded-t-[1000px] absolute top-0 left-0">
        <div className="container w-full">
          <div className="flex flex-col items-center justify-between py-8 gap-4">
            <Image
              width={120}
              height={50}
              src="/logo.png"
              alt=""
              className="w-[120px] h-auto"
            />

            <ul className="flex gap-4 invert">
              <a href="https://x.com/nftOS2024" target="_blank">
                <Image
                  width={120}
                  height={50}
                  src="/twitter.svg"
                  alt=""
                  className="text-white w-8 h-auto"
                />
              </a>
              <a
                href="https://www.linkedin.com/showcase/nftos-marketplace/"
                target="_blank"
              >
                <Image
                  width={120}
                  height={50}
                  src="/linkedin.svg"
                  alt=""
                  className="w-8 h-auto"
                />
              </a>
            </ul>
            <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
              <li>
                <Link
                  href="/tarms"
                  className="hover:text-accent dark:hover:text-white"
                >
                  © 2024 Trillicorn Technology
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default footer;
