// Direct Buy

import { bidsModalHide } from "../redux/counterSlice";
import { resetBalanceInfo } from "../redux/data/balanceSlice";
import { updateTransactionsData } from "../redux/data/dataSlice";
import { getCreatorAddress } from "../services/nfts";
import { claimAuction, placeBid, purchaseListing } from "../services/services";
import { roundToDecimalPlaces } from "./basicFunctions";
import { createTransaction } from "./dbFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";

const useTransactionFunctions = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { transactionsData } = useSelector((state) => state.data);

  const handleDirectBuy = async (
    tokenId,
    ownerWallet,
    initialPrice,
    royalty,
    listedAmount,
    userWallet
  ) => {
    try {
      const creator_address = await getCreatorAddress(tokenId);
      const response = await purchaseListing(
        tokenId,
        ownerWallet,
        initialPrice,
        royalty,
        creator_address
      );

      if (response) {
        await createTransaction(
          "transfer",
          tokenId,
          "Purchase made for " + initialPrice + " ETH",
          "Sold to " +
            userWallet.slice(0, 10) +
            " for " +
            initialPrice +
            " ETH",
          userWallet,
          ownerWallet,
          listedAmount,
          roundToDecimalPlaces(initialPrice, 4),
          "direct-purchase"
        );
        const data = {
          tokenId: tokenId,
          eventName: "transfer",
          action: `direct-purchase`,
          senderResponse: "Purchase made for " + initialPrice + " ETH",
          receiverResponse:
            "Sold to " +
            userWallet.slice(0, 10) +
            " for " +
            initialPrice +
            " ETH",
          senderWallet: userWallet,
          receiverWallet: ownerWallet,
          quantity: listedAmount,
          price: roundToDecimalPlaces(initialPrice, 4),
          happenedAt: new Date().toISOString(),
        };
        const currentTransactionData = Array.isArray(transactionsData)
          ? transactionsData
          : [];
        dispatch(updateTransactionsData([...currentTransactionData, data]));
        dispatch(resetBalanceInfo());
        alert("Purchase successful!");
      }
    } catch (e) {
      console.error("Error:", e);
    }
  };

  const handlePlaceBids = async (
    tokenId,
    ownerAddress,
    ETHAmount,
    bidderAddress,
    auctionedAmount
  ) => {
    try {
      const response = await placeBid(tokenId, ownerAddress, ETHAmount);

      if (response) {
        await createTransaction(
          "bids",
          tokenId,
          "Bid placed for " + ETHAmount + " ETH",
          "Bid placed by " +
            bidderAddress.slice(0, 10) +
            " for " +
            ETHAmount +
            " ETH",
          bidderAddress,
          ownerAddress,
          auctionedAmount,
          roundToDecimalPlaces(ETHAmount, 4),
          "place"
        );
        const data = {
          tokenId: tokenId,
          eventName: "bids",
          action: `place`,
          senderResponse: "Bid placed for " + ETHAmount + " ETH",
          receiverResponse:
            "Bid placed by " +
            bidderAddress.slice(0, 10) +
            " for " +
            ETHAmount +
            " ETH",
          senderWallet: bidderAddress,
          receiverWallet: ownerAddress,
          quantity: auctionedAmount,
          price: roundToDecimalPlaces(ETHAmount, 4),
          happenedAt: new Date().toISOString(),
        };
        const currentTransactionData = Array.isArray(transactionsData)
          ? transactionsData
          : [];
        dispatch(updateTransactionsData([...currentTransactionData, data]));
        dispatch(resetBalanceInfo());
        alert("Bid placed successfully");
        dispatch(bidsModalHide());
      }
    } catch (error) {
      console.error("Error placing bid:", error);
    }
  };

  const handleClaimAuction = async (
    tokenId,
    ownerAddress,
    bidInWei,
    royality,
    creatorAddress,
    currentBidMoney,
    tokenAmount,
    bidderAddress
  ) => {
    try {
      const response = await claimAuction(
        tokenId,
        ownerAddress,
        bidInWei,
        royality,
        creatorAddress
      );

      if (response) {
        await createTransaction(
          "transfer",
          tokenId,
          "Auction claimed by you",
          `Auction claimed, transferred ${currentBidMoney} to you`,
          bidderAddress,
          ownerAddress,
          tokenAmount,
          currentBidMoney,
          "claim auction"
        );
        const data = {
          tokenId: tokenId,
          eventName: "transfer",
          action: `claim auction`,
          senderResponse: "Auction claimed by you",
          receiverResponse: `Auction claimed, transferred ${currentBidMoney} to you`,
          senderWallet: bidderAddress,
          receiverWallet: ownerAddress,
          quantity: tokenAmount,
          price: currentBidMoney,
          happenedAt: new Date().toISOString(),
        };

        const currentTransactionData = Array.isArray(transactionsData)
          ? transactionsData
          : [];
        dispatch(updateTransactionsData([...currentTransactionData, data]));
        dispatch(resetBalanceInfo());

        alert("Auction Claimed Successfully");
        router.push("/user/" + user.username);
      }
    } catch (e) {
      alert("Failed to accept offer: " + e.message);
      console.error("Error:", e);
    }
  };

  return {
    handleClaimAuction,
    handleDirectBuy,
    handlePlaceBids,
  };
};

export default useTransactionFunctions;
