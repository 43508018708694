import { configureStore } from "@reduxjs/toolkit";
// import counterReducer from '../features/counter/counterSlice';
import counterReducer from "./counterSlice";
import popupItemReducer from "./popupItemSlice";
import itemsReducer from "./itemDataSlice";
import dashReducer from "./dashSlice";
import dataReducer from "./data/dataSlice";
import balanceReducer from "./data/balanceSlice";
import tokensReducer from "./data/tokenSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    popupItems: popupItemReducer,
    items: itemsReducer,
    dash: dashReducer,
    data: dataReducer,
    balance: balanceReducer,
    tokens: tokensReducer,
  },
});
