import React from "react";

const DisableShield = () => {
  return (
    <>
      <div className=" bg-[black] opacity-15 fixed top-0 left-0 z-[10000000]"></div>
      <div className="w-full h-[100vh] fixed top-0 left-0 flex items-end justify-end z-[1000] text-[#939393] p-2 text-[12px] pointer-events-none select-none">
        <p className="max-w-[250px]">
          Please do not Close this window or Internet Connection while
          processing...
        </p>
      </div>
    </>
  );
};

export default DisableShield;
