import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBalanceOfNFT, getAllTokenIds } from "../../services/nfts";
import { getListing, getAuctions } from "../../services/services";
import { roundToDecimalPlaces } from "../../utils/basicFunctions";

// Async thunk for fetching balance info
export const fetchBalanceInfo = createAsyncThunk(
  "balance/fetchBalanceInfo",
  async ({ userDetails }) => {
    const tokenIDs = await getTokenIds();
    if (!userDetails?.length || !tokenIDs?.length) {
      return [];
    }

    const balanceArray = await Promise.all(
      userDetails.map(async (userDetail) => {
        const { walletAddress, name, profilePhotoUrl } = userDetail;

        const balancePromises = tokenIDs.map(async (tokenID) => {
          try {
            const [nftBalance, listData, auctionData] = await Promise.all([
              getBalanceOfNFT(tokenID, walletAddress),
              getListing(tokenID, walletAddress),
              getAuctions(tokenID, walletAddress),
            ]);

            const balance = Number(nftBalance);
            const isListed = listData?.isListed;
            const isActive = auctionData?.isActive;
            const isDirect = listData?.isDirect;

            if (
              (balance === 0 && isListed) ||
              balance > 0 ||
              (balance === 0 && isActive)
            ) {
              return {
                tokenID: Number(tokenID),
                walletAddress,
                name,
                photoUrl: profilePhotoUrl,
                balance,
                isListed,
                isActive,
                isDirect,
                auctionDuration: auctionData.duration,
                highestBidder: auctionData.highestBidder,
                auctionPrice: auctionData.isActive
                  ? roundToDecimalPlaces(
                      Math.max(
                        auctionData.startingPrice / 1e18,
                        auctionData.currentBid / 1e18
                      ),
                      4
                    )
                  : 0,
                listingPrice: listData.isListed
                  ? roundToDecimalPlaces(
                      Math.max(
                        listData.price / 1e18,
                        listData.currentOffer / 1e18
                      ),
                      4
                    )
                  : 0,
                listAmount: listData.amount,
                auctionAmount: auctionData.amount,
              };
            }
          } catch (error) {
            console.error(
              `Failed to get balance for ${walletAddress} with token ${tokenID}:`,
              error
            );
          }
        });

        const userBalances = await Promise.all(balancePromises);
        return userBalances.filter(Boolean);
      })
    );

    return balanceArray.flat();
  }
);

async function getTokenIds() {
  try {
    const response = await getAllTokenIds();
    if (!response) {
      return [];
    }
    return response;
  } catch (error) {
    return [];
  }
}

const balanceSlice = createSlice({
  name: "balance",
  initialState: {
    balanceInfo: [],
    error: null,
  },
  reducers: {
    resetBalanceInfo: (state) => {
      state.balanceInfo = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBalanceInfo.fulfilled, (state, action) => {
        state.balanceInfo = action.payload;
      })
      .addCase(fetchBalanceInfo.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

export const { resetBalanceInfo } = balanceSlice.actions;

export default balanceSlice.reducer;
