import Footer from "./footer";
import Wallet_modal from "./modal/wallet_modal";
import BidsModal from "./modal/bidsModal";
import BuyModal from "./modal/buyModal";
import { useRouter } from "next/router";

export default function Layout({ children }) {
  const route = useRouter();
  const routesWithFooter = [
    "/prop/property_world",
    "/prop/product_gallery",
    "/rankings",
  ];

  const isDynamicCollectionRoute = (path) => {
    const regex = /^\/collection\/[^\/]+\/[^\/]+$/;
    return regex.test(path);
  };

  const shouldShowFooter =
    routesWithFooter.includes(route.asPath) ||
    route.asPath.startsWith("/user/") ||
    route.asPath.startsWith("/item") ||
    isDynamicCollectionRoute(route.asPath);

  return (
    <>
      <Wallet_modal />
      <BidsModal />
      <BuyModal />
      <main>{children}</main>
      {!shouldShowFooter ? null : <Footer />}
    </>
  );
}
