import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    screenSize:undefined,
    activeMenu:true,
}
export const dashSlice = createSlice({
    name:'dash',
    initialState,
    reducers:{
        setScreenSize(state,action){
            state.screenSize = action.payload;
        },
        setActiveMenu(state,action){
            state.activeMenu = action.payload;
        }
    }
})


export const {
    setActiveMenu,
    setScreenSize
} = dashSlice.actions;

export default dashSlice.reducer;