import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: {},
  currentItemId: null,
};

const popupItemSlice = createSlice({
  name: "popupItems",
  initialState,
  reducers: {
    setPopupItem: (state, action) => {
      const { id, data } = action.payload;
      state.currentItemId = id;

      if (!state.items[id]) {
        state.items[id] = data;
      }
    },

    updatePopupItem: (state, action) => {
      const { id, index, data } = action.payload;
      if (state.items[id]) {
        state.items[id][index] = { ...state.items[id][index], ...data };
      }
    },
    removePopupItem: (state, action) => {
      const { id, index } = action.payload;
      if (state.items[id]) {
        state.items[id].splice(index, 1);
      }
      if (state.items[id].length == 0) {
        state.items[id].push({ trait_type: '', value: '' });
      }
    },

    addPopupItem: (state, action) => {
      const { id } = action.payload;
      if (state.items[id]) {
        state.items[id].push({ trait_type: '', value: '' });
      }
    }
  }
})

export const { setPopupItem, updatePopupItem, addPopupItem, removePopupItem } = popupItemSlice.actions;

export default popupItemSlice.reducer;