import React, { createContext, useContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';

// Create a context for the wallet
const WalletContext = createContext();

export function useWallet() {
  return useContext(WalletContext);
}

// Define the WalletProvider component
export function WalletProvider({ children }) {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [account, setAccount] = useState(null);
  const [chainId, setChainId] = useState(null);

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      // Create a new provider
      const ethersProvider = new ethers.BrowserProvider(window.ethereum);
      setProvider(ethersProvider);

      // Listen for account changes
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length > 0) {
          setAccount(accounts[0]);
        } else {
          setAccount(null);
        }
      });

      // Listen for chain changes
      window.ethereum.on('chainChanged', (chainIdHex) => {
        setChainId(parseInt(chainIdHex, 16)); // Convert chainId from hex to decimal
      });

      // Initialize provider and state
      (async () => {
        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const signer = ethersProvider.getSigner();
          setSigner(signer);
          const accounts = await ethereum?.request?.({ method: 'eth_accounts' });
          setAccount(accounts[0]);
          const network = await ethersProvider.getNetwork();
          setChainId(network.chainId);
        } catch (error) {
          console.error('Failed to connect wallet:', error);
        }
      })();
    } else {
      console.error('MetaMask is not installed');
    }
  }, []);

  const connectWallet = async () => {
    if (!provider) return;
    try {
      await provider.send('eth_requestAccounts', []);
      const signer = provider.getSigner();
      setSigner(signer);
      const accounts = await ethereum?.request?.({ method: 'eth_accounts' });
      setAccount(accounts[0]);
      const network = await provider.getNetwork();
      setChainId(network.chainId);
    } catch (error) {
      console.error('Failed to connect wallet:', error);
    }
  };

  const value = {
    provider,
    signer,
    account,
    chainId,
    connectWallet,
  };

  return (
    <WalletContext.Provider value={value}>
      {children}
    </WalletContext.Provider>
  );
}
