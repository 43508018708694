import React, { useContext, useState } from "react";
import { ethers } from "ethers";
import { create as ipfsHttpClient } from "ipfs-http-client";

// Import constants
import {
  mintContractAddress,
  mintContractAbi,
  marketplaceContractAbi,
  marketplaceContractAddress,
} from "./constants";

// Create IPFS client
const client = ipfsHttpClient("/ip4/127.0.0.1/tcp/5001");

// Context
const EthereumContext = React.createContext();

// Custom hook to access Ethereum context
export const useEthereum = () => useContext(EthereumContext);

  
// Provider component
export const EthereumProvider = ({ children }) => {
  const data = "hey there here i am ";
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);

  // Function to initialize provider and signer
  const initializeProvider = async () => {
    let ethProvider;
    if (window.ethereum == null) {
      ethProvider = ethers.getDefaultProvider();
    } else {
      ethProvider = new ethers.BrowserProvider(window.ethereum);
    }
    setProvider(ethProvider);
    setSigner(await ethProvider.getSigner());
  };

  // Upload asset to IPFS
  const uploadIPFSAsset = async (asset) => {
    const result = await client.add(asset);
    return `https://ipfs.infura.io/ipfs/${result.path}`;
  };

  // Get Mint contract
  const getMintContract = async () => {
    if (!provider || !signer) await initializeProvider();
    return new ethers.Contract(mintContractAddress, mintContractAbi, signer);
  };

  // Get Marketplace contract
  const getMarketplaceContract = async () => {
    if (!provider || !signer) await initializeProvider();
    return new ethers.Contract(
      marketplaceContractAddress,
      marketplaceContractAbi,
      signer
    );
  };

  // Mint NFT
  const mintNFT = async (supply, dataURL) => {
    const contract = await getMintContract();
    const txn = await contract.mint(+parseInt(supply), "0x", dataURL, {
      gasPrice: 20e9,
    });
    const reciept = await txn.wait();
    return reciept.logs.find((l) => l.fragment.name === "TokenMinted")?.args[0].toString();
  };

  // Expose the provider and functions to the application
  const contextValue = {
    provider,
    signer,
    getMintContract,
    getMarketplaceContract,
    uploadIPFSAsset,
    mintNFT,
    data
  };

  return (
    <EthereumContext.Provider value={contextValue}>
      {children}
    </EthereumContext.Provider>
  );
};