import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCreatedNFTs } from "../../services/nfts";
import { ethers } from "ethers";
// Async thunk for fetching tokens data
export const fetchTokensData = createAsyncThunk(
  "tokens/fetchTokensData",
  async () => {
    const data = await getCreatedNFTs();
    if (Array.isArray(data) && data.length > 0) {
      const tokensData = await Promise.all(
        data.map(async (nft) => {
          const uriData = await fetchData(nft.uri || nft.url);
          const tokenData = {
            id: nft.token_id,
            creatorAddress:
              nft.creator_address || ethers.ZeroAddress.toString(),
          };
          // Fetch additional data from the URI
          return { ...tokenData, ...uriData };
        })
      );
      return tokensData;
    } else {
      throw new Error("No NFTs created");
    }
  }
);

async function fetchData(url) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null; // Return null in case of error so the map can handle it gracefully
  }
}

const tokenSlice = createSlice({
  name: "tokens",
  initialState: {
    tokensData: [],
    tokenError: null,
  },
  reducers: {
    resetTokensData: (state) => {
      state.tokensData = [];
    },
    updateTokenData: (state, action) => {
      state.tokensData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTokensData.fulfilled, (state, action) => {
        state.tokensData = action.payload;
      })
      .addCase(fetchTokensData.rejected, (state, action) => {
        state.tokenError = action.error.message;
      });
  },
});

export const { resetTokensData, updateTokenData } = tokenSlice.actions;

export default tokenSlice.reducer;
