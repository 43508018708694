import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    itemsData:[]
}

export const itemsSlice = createSlice({
    name: 'items',
    initialState,
    reducers:{
        setItemsData: (state, action) => {
            state.itemsData =action.payload;
        }
    }
})

export const {
    setItemsData,
} = itemsSlice.actions;

export default itemsSlice.reducer;