import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Async thunks for fetching data
export const fetchUserDetails = createAsyncThunk(
  "data/fetchUserDetails",
  async () => {
    const response = await fetch("/api/users/user-details");
    const data = await response.json();
    return data.userDetails;
  }
);

export const fetchAllNfts = createAsyncThunk("data/fetchAllNfts", async () => {
  const response = await fetch("/api/nfts/search-nft");
  const data = await response.json();
  return data.nfts;
});

export const fetchCollectionsData = createAsyncThunk(
  "data/fetchCollectionsData",
  async () => {
    const response = await fetch("/api/collections/get-collections");
    const data = await response.json();
    return data.collections;
  }
);

export const fetchTransactionsData = createAsyncThunk(
  "data/fetchTransactionsData",
  async () => {
    const response = await fetch("/api/transactions/fetch-activity");
    const data = await response.json();
    return data.transactions;
  }
);

export const fetchCurrencyToUsd = createAsyncThunk(
  "data/fetchCurrencyToUsd",
  async ({ currency }) => {
    const response = await fetch(
      `https://api.coingecko.com/api/v3/simple/price?ids=${currency}&vs_currencies=usd`
    );
    const data = response.data;
    const currToUsdRate = data[`${currency}`].usd;
    return currToUsdRate;
  }
);

const dataSlice = createSlice({
  name: "data",
  initialState: {
    userDetails: [],
    nftsData: [],
    collectionsData: [],
    transactionsData: [],
    currToUsdRate: 2527.354997,
    error: null,
  },
  reducers: {
    resetUserDetails: (state) => {
      state.userDetails = [];
    },
    resetNftsData: (state) => {
      state.nftsData = [];
    },
    resetCollectionsData: (state) => {
      state.collectionsData = [];
    },
    resetTransactionsData: (state) => {
      state.transactionsData = [];
    },
    resetError: (state) => {
      state.error = null;
    },
    updateUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    updateNftsData: (state, action) => {
      state.nftsData = action.payload;
    },
    updateCollectionsData: (state, action) => {
      state.collectionsData = action.payload;
    },
    updateTransactionsData: (state, action) => {
      state.transactionsData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.userDetails = action.payload;
      })
      .addCase(fetchAllNfts.fulfilled, (state, action) => {
        state.nftsData = action.payload;
      })
      .addCase(fetchCollectionsData.fulfilled, (state, action) => {
        state.collectionsData = action.payload;
      })
      .addCase(fetchTransactionsData.fulfilled, (state, action) => {
        state.transactionsData = action.payload;
      })
      .addCase(fetchCurrencyToUsd.fulfilled, (state, action) => {
        state.currToUsdRate = action.payload;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(fetchAllNfts.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(fetchCollectionsData.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(fetchTransactionsData.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(fetchCurrencyToUsd.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});
export const {
  resetUserDetails,
  resetNftsData,
  resetCollectionsData,
  resetTransactionsData,
  updateUserDetails,
  updateNftsData,
  updateCollectionsData,
  updateTransactionsData,
  resetError,
} = dataSlice.actions;
export default dataSlice.reducer;
